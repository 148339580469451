@import "../../../view/styles/variables";

.data-notfound{
    display: flex;
    align-items: center;
    justify-content: center;
}
.text-notfound{
    font-size: calc(3rem - 15px);
    font-weight: 400;
    background-color: $background-white;
    border-radius: 20px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0,0, 0.20);
    padding: 25px 150px;
    color: #808080 ;
  }
  .page-height{
    height: calc(100vh - 100px) !important;
  }

@media (max-width: $sm){
    .text-notfound{
        font-size: 1.5rem;
        padding: 25px 100px;
      }
}
