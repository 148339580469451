// @import "~bootstrap/scss/bootstrap";
@import "https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
// @import "reset.css";
@import "_variables";
@import "_mixins";
@import "_helpers";
@import "_elements";
@import "_typography";
@import "_forms";
@import "_utils";

html {
  box-sizing: border-box;
  font-size: 62.5%;

  @media (min-width: $xs) {
    font-size: 75%;
  }

  @media (min-width: $lg) {
    font-size: 75%;
  }

  @media (min-width: (($lg + $xl) / 2)) {
    font-size: 80%;
  }

  @media (min-width: $xl) {
    font-size: 90%;
  }

  @media (min-width: $xxl) {
    font-size: 100%;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  display: flex;
  flex-direction: column;
  min-width: 250px;
  min-height: 100vh;
  color: $color-text;
  font-family: $ff-base;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
}

*::selection {
  background-color: $color-accent;
  color: $color-text-invert;
}
ul , ol{
  margin: 0px !important;
  padding: 0px !important;
}
ul li{
  list-style: none;
}
#header .logo-img img{
  width: 200px;
  height: 80px;
}
.btn-save{
  height: 40px;
  padding: 10px 40px;
  background-color: $background-header;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  border: 0px;
  border-radius: 10px;
  line-height: 0%;
}
.btn-cancle{
  height: 40px;
  padding: 10px 40px;
  border: 1px solid #BCC1CD;
  background-color: $background-white;
  color: #BCC1CD;
  font-weight: 700;
  font-size: 18px;
  border-radius: 10px;
  line-height: 0%;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}
@media (max-width: $lg){
 
}
@media (max-width: $md){
  
  
}
@media (max-width: $sm){

  .btn-save{
    height: 40px;
    font-weight: 600;
    font-size: 14px;
    padding: 10px 30px;

  }
  .btn-cancle{
    height: 40px;
    font-weight: 600;
    font-size: 14px;
    padding: 10px 30px;
  }
  #header .logo-img img{
    width: 160px;
    height: 55px;
}
  
}


@media (max-width: $xs){
 
}


