@import "_variables";

.container {
  max-width: 1500px;
  margin: 0 auto;
  padding: 0 $gutter-h;

  &#{&}_contain-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media (min-width: 1300px) {
    max-width: 1500px;

    &#{&}_narrow {
      max-width: $max-width;
    }

    &#{&}_extra-width {
      max-width: 1900px;
    }
  }
}

.screen {
  flex: 1;

  &#{&}_flex {
    display: flex;
  }

  &#{&}_column {
    display: flex;
    flex-direction: column;
  }

  &#{&}_with-content {
    padding: rem(30px) 0;
  }
}

.block {
  margin: rem(22px) 0;

  &__heading {
    font-size: rem(26px);
    margin-bottom: em(13px, 26px);

    &#{&}_min {
      font-size: rem($font-size-regular);
      font-weight: 700;
    }
  }
}

.app-store-link {
  transition: transform $time-duration linear;

  &:hover {
    transform: scale(1.05);
  }
}

.btn {
  $base: $font-size-regular;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  min-height: rem(44px);
  border: 2px currentColor solid;
  padding: rem(5px);
  border-radius: $border-rad-sm;
  font-size: rem($base);
  font-weight: 700;
  background-color: #fff;
  color: $color-accent;
  text-align: center;
  outline: none !important;
  cursor: pointer;
  line-height: 1.2;

  &:focus {
    color: darken($color-accent, 20%);
  }

  &::after {
    content: "";
    @include fill;
    background: currentColor;
    opacity: 0;
    transition: opacity $time-duration-min linear;
  }

  &:hover,
  &:active {
    text-decoration: none;
  }

  &:hover::after {
    opacity: 0.2;
  }

  &:active::after {
    opacity: 0.4;
  }

  &:disabled,
  &#{&}_disabled {
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
  }

  & &__icon {
    $icon_margin: 0.3em;
    margin-right: $icon_margin;

    &_right {
      margin-right: 0;
      margin-left: $icon_margin;
    }

    &_danger {
      color: $color-danger;
    }

    &_larger {
      font-size: 1.3em;
    }
  }

  & &__progress {
    $progress_size: em(17px, $base);
    position: relative;
    top: -0.05em;
    width: $progress_size !important;
    height: $progress_size !important;
    margin-right: $progress_size / 2;
    color: currentColor;
  }

  &#{&}_block {
    display: flex;
    width: 100%;
  }

  &#{&}_accent {
    background-color: $color-accent;
    border-color: $color-accent;
    color: $color-text-invert;

    &:focus {
      background-color: darken($color-accent, 15%);
      border-color: darken($color-accent, 15%);
    }
  }

  &#{&}_reject {
    background-color: $color-text-invert;
    border-color: $color-danger;
    color: $color-danger;

    &:hover {
      background-color: darken($color-danger, 0%) !important;
      border-color: darken($color-danger, 0%) !important;
      color: $color-text-invert !important;
    }

    &:focus {
      background-color: darken($color-danger, 0%);
      border-color: darken($color-danger, 0%);
      color: $color-text-invert !important;
    }
  }

  &#{&}_reject-outline {
    border-color: currentColor;
    color: $color-danger;
  }

  &#{&}_solid {
    background: $color-dark-solid;
    border-color: $color-dark-solid;
    color: #fff;

    &:focus {
      background: #000;
      border-color: #000;
    }
  }
}

@media (max-width: 1440px) {
  .btn_export_15 {
    font-size: 14px;
  }
}

@media (max-width: 1199px) {
  .btn_export_15 {
    font-size: 12px;
  }
}

.btn-bar {
  display: flex;
  justify-content: center;
  gap: rem(5px);
  margin-top: rem(10px);
  padding-top: rem(10px);
  border-top: 1px $color-border solid;

  .btn {
    min-width: rem(85px);
    padding-right: rem(15px);
    padding-left: rem(15px);
  }
}

.img-fluid {
  width: 100%;
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

.img-rounded {
  border-radius: rem(20px);
}

.section {
  $self: &;
  margin-top: rem(10px);
  padding: rem(15px);
  border: 1px $color-border solid;
  border-radius: $border-rad-md;

  &.section_group > .section__title + * {
    padding-top: rem(10px);
  }

  &__title {
    font-size: rem($font-size-regular);
    font-weight: 600;
    margin-bottom: 0.2em;
  }

  & > &__title {
    margin-top: -1.62em;
    margin-bottom: 0.5em;
    padding: 0 rem(5px);
    background: #fff;
    width: fit-content;
    line-height: 1.2;

    @media (min-width: $lg) {
      &_long {
        width: auto;
        max-width: 305px;
      }
    }
  }

  & & {
    border-color: lighten($color-border, 5%);

    > #{$self}__title {
      margin-top: -1.66em;
      margin-bottom: 1em;
    }
  }
}
