@import "../../view/styles/variables";

.header .logo-img img{
    width: 230px;
    height: 80px;
}
.header .logo-img{
    padding: 10px 0px;
    background-color: $background-header;
}


// @media (max-width: $sm){
//     .text-notfound{
//         font-size: 1.5rem;
//         padding: 25px 100px;
//       }
// }
