
@import "_variables";

.actionIcons {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.flex_center {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.inline_flex {
    display: inline-flex !important;
}

.gap_normal {
    gap: 0.5rem;
}

.text_center {
    text-align: center !important;
}

.cursor_none {
    cursor: not-allowed !important;
}

.cursor_pointer {
    cursor: pointer !important;
}

.custom-btn {
    
}

