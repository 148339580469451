@import "../../styles/variables";

  #userLogin {
    background-color: $background-primary;
    .login{
      width: 100%;
      height: calc(100vh - 100px);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .user-login{
      background-color: $background-white;
      border-radius: 10px;
      box-shadow: 0px 0px 4px 2px rgba(0, 148, 255, 0.25);
      padding: 60px;
    }
    .logo-img img{
      width: 40%;
      height:auto;
      margin: 0 auto;
      display: block;
    }
    .frm-input{
      height: 60px;
      .form-control{
        width: 100%;
        height: 45px;
        border: 1px solid  rgba(0, 148, 255,0.7);
        border-radius: 10px;
        
      }
    }
    .btn-save{
      height: 45px;
      width: 200px;
      background-color: #0094FF;
      color: #fff;
      font-weight: 700;
      font-size: 16px;
      border: 0px;
      border-radius: 10px;
    }
  }
  
  @media (max-width: $sm){
    #userLogin  {
      background-color: $background-white;
      .login{
        background-color: $background-white;
      }
      .frm-input .form-control{
        width: 290px;
        height: 40px;
        
      }
      .btn-save{
        height: 40px;
        width: 150px;
        font-size: 14px;
      }
      .user-login{
        box-shadow: 0px 0px 4px 0px rgba(0,0, 0, 0.25);
        padding:20px;
      }
      .logo-img img{
        width: 150px;
        height: 80px;
        margin: 0 auto;
        display: block;
        margin-bottom: 0px;
      }
    }
  
  }