@import "../../styles/variables";

#lastVisitInfo {
    height: 100vh;
    width: 100%;
    background-color: $background-primary;
    // overflow-y:scroll ;
    .logo-img{
        padding: 10px 0px;
        background-color: $background-white;
        img{
            width: 230px;
            height: 80px;
        }
    }

    
}
.lastVisitInfo{
    height: calc(100vh - 100px) !important;
    overflow-y: auto !important;
}
.info{
    width: 100%;
    margin-bottom: 10px;
    span{
        font-size: 18px;
        color: #000;
        font-weight: 500;
        line-height: 24.2px;
    }
}

.instruction-info ,.exercise-info{
    background-color: $background-white;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0,0, 0.20);
    width: 100%;
    height:calc(100vh - 300px);
    overflow-y: auto;
}
.modal-content{
    border-radius: 15px !important;
}
 .modal-body{
    padding: 0px !important;
}
.modal-body img{
    height:100% !important;
    max-width:100% !important;
    border-radius: 15px;
}

.exercise-image{
    height: 80px;
    width: 80px;
    border-radius: 10px;
    margin: 10px;
}

@media (max-width: $lg){
 
}
@media (max-width: $md){
   
   
    .instruction-info ,.exercise-info{
        background-color: #F3F1F1;
        height:auto;
        margin: 20px 0px;
        padding: 10px;
        overflow-y:auto
    }
    
    .info span{
        font-size: 12px;
        line-height: 15px;
    }
}
@media (max-width: $sm){

    .mobile-info{
        width: 100%;
        display: flex;
        justify-content:space-between ;
        align-items: center;
    }
   
    .info span{
        display: block;
    }
    #lastVisitInfo .logo-img img{
        width: 160px;
        height: 55px;
    }
    #lastVisitInfo .logo-img{
        background-color: $background-header;
    }
    .instruction-info ,.exercise-info{
        background-color: #F3F1F1;
        height:auto;
        margin: 20px 0px;
        padding: 10px;
        overflow-y:auto
    }
    .info span{
        font-size: 16px;
        color: #000;
        font-weight: 500;
        line-height: 24.2px;
    }
    .span-light{
        color: #808080 !important;
    }
    #lastVisitInfo{
        height: 100vh;
        width: 100%;
        background-color: $background-white;
    }
    .exercise-image{
        height: 50px;
        width: 50px;
    margin: 5px;

    
    }
    .d-icon-none{
        display: none !important; 
    }
}

@media (max-width: $xs){
 
}

