@import "../../styles/variables";

  #Appointment {
    background-color: $background-primary;
    height: 100vh;
    width: 100%;
   
    .btn-appointment{
      height: calc(100vh - 100px);
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      button{
        height: 300px;
        width: 500px;
        background: #fff;
        border: none;
        border-radius: 10px;
        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
        font-size: 20px;
        font-weight: 500;
      }
    }
    
  }


  @media (min-width: 992px) and (max-width: 1199.98px){
    .btn-appointment  button{
      height: 250px !important;
      width: 350px !important;
      margin: 10px 10px !important;
    }
  }
  @media (min-width: 768px) and (max-width: 991.98px) { 
   
    .btn-appointment  button{
      height: 220px !important;
      width: 320px !important;
      margin: 10px 10px !important;
    }
    
 }
 @media (min-width: 576px) and (max-width: 767.98px) {
  .btn-appointment{
    display: block !important;
    text-align: center;
    button{
      height: 200px !important;
    width: 300px !important;
      margin: 10px 10px !important;
    }
  }
 }
 @media (max-width: 576px){
  .btn-appointment{
          display: block !important;
          text-align: center;
          button{
            height: 200px !important;
          width: 300px !important;
            margin: 10px 10px !important;
          }
        }
 }

