@import "../../styles/variables";

#bookAppointment,#waitingList
{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100vh - 250px) ;
    padding:20px;
}


.book-appointment ,.waiting-list {
  
    box-shadow: 0px 0px 4px 2px $background-header;
    border-radius: 15px;
    padding:20px 30px;
}
#bookAppointment{
     .form-label{
    color:$label-color;
    font-size: 1rem;
    }
    .form-control{
        background-color: #F3F1F1 !important;
        border: none !important;
        height:45px;
        border-radius: 10px;
    }
    input::placeholder{
        font-size: 14px;
        color: #C2BDBD;
      }
    .frm-input{
        height: 85px;
      }
      .form-control:focus {
        border-color:none !important;
        box-shadow: none !important;
    }
    .text-notfound{
        padding: 10px 50px;
        font-size: 1rem;
        border-radius: 10px;
    }
}

.info-table{
    background-color: $background-white;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
    width: 500px !important;
    border-radius: 5px;
    padding: 10px;
    table tr td{
        padding: 5px 0px;
        font-size: 14px;
        color: #000;
        font-weight: 500;
    }
}

.patient-list{
    background-color: #FBFBFB;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
    padding: 5px 10px;
    border-radius: 5px;
    margin: 5px 0px;
}
.waiting-patient, .aprox-time,.appointment-time{
    width:420px ;
    background-color: $background-header;
    padding: 10px 20px;
    border-radius: 10px;
    margin: 20px 0px;
    span{
        color: $text-white;
        font-size: 16px;
        font-weight: 500;
    }

}

.patient-data{
    height: 200px;
    overflow-y: auto;
}
.confirm-modal {
    .modal-header {
        background-color: $background-header;
        border-bottom: 0px;
        color: $text-white;
        span, svg{
            font-size: 20px !important;
        }
    }
    .modal-content{
        border: 2px solid $background-header !important;
    }
    
    .modal-body{
        padding: 13px !important;
        p{
            font-size: 1.2rem;
        }
    }
    .modal-footer{
        border-top: 0px !important;
    }
    .btn-save{
        padding:10px 50px ;
    }
}
.waiting-list .h-500{
    height: 550px;
}

@media (max-width: $sm){
    #bookAppointment
    {
        align-items: start;
        width: 100%;
        height: calc(100vh - 200px);
        .book-appointment{
            box-shadow:none;
            border-radius: none;
            padding:0px;
            height: calc(100vh - 200px) !important;
            overflow-y: auto !important;
        }
        .title{
            margin-bottom: 20px !important;
        }
        .form-label{
            color:$label-color;
            font-size: calc(1.5rem - 4px);
        
            }
    }

    .book-appointment::-webkit-scrollbar , .waiting-list::-webkit-scrollbar  {
        display: none;
    }

   
.book-appointment .h-500 , .waiting-list .h-500{
    height: 500px;
}

    .waiting-list{
        height: calc(100vh - 200px) !important;
        width: 100% !important;
        padding: 0px;
        box-shadow: none;
        border-radius: none;
        overflow-y: auto !important;
    }
    .waiting-patient, .aprox-time,.appointment-time{
        width:100% !important ;
        span{
            color: $text-white;
            font-size: 14px;
            font-weight: 500;
        }
    }
    .info-table{
        width: 100% !important;
    }
  
  
    .confirm-modal {
        .modal-header {
            
            span, svg{
                font-size: 18px !important;
            }
        }
        .icon-close{
            font-size: 2rem;
        }
        .modal-body{
            padding:10px 13px !important;
        }
        .btn-save{
            padding:10px 50px ;
        }
    }
    #waitingList{
        height: auto !important;
    }
  }